import { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Footer from "app/uikit/table/footer";
import actions from "app/store/trends/actions";
import CircularProgress from "app/uikit/CircularProgress";
import EmptyData from "app/uikit/table/EmptyData";
import { useSubscribe } from "app/hooks/useSubscribe";
import { IPagination } from "app/types";
import { ITrendItem } from "app/store/trends/type";
import ConfirmModal from "app/components/modal/confirm-modal";
import { useIsAdmin } from "app/hooks/useIsAdmin";
import { configSelect } from "app/store/select";

import style from "./style.module.scss";
import TrendCard from "./trendCard";
import HideContent from "./hideContent";

const TrendsContent = () => {
	const { isAdmin } = useIsAdmin();
	const [deleteInfo, setDeleteInfo] = useState<ITrendItem | null>(null);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const { trends, isLoading, pagination } = useSelector(configSelect.trends);
	const subscribe = useSubscribe();

	const dispatch = useDispatch();

	const onPaginationChange = useCallback(
		(data: IPagination) => {
			dispatch(actions.fetchPagination(data));
		},
		[dispatch]
	);

	const onCloseDeleteModal = useCallback(() => {
		setDeleteOpen(false);
	}, [setDeleteOpen]);

	const onOpenDeleteModal = useCallback(
		(info: ITrendItem) => {
			setDeleteInfo(info);
			setDeleteOpen(true);
		},
		[setDeleteOpen]
	);

	const onDeleteAgree = useCallback(() => {
		dispatch(actions.fetchDeleteTrend(deleteInfo.id, onCloseDeleteModal));
	}, [dispatch, onCloseDeleteModal, deleteInfo]);

	return (
		<>
			<div className={style.container}>
				{trends.items.map(item => (
					<TrendCard
						key={item.id}
						isAdmin={isAdmin}
						data={item}
						onDelete={onOpenDeleteModal}
					/>
				))}
				{!!trends?.count && <HideContent />}
				{!trends?.count && (
					<EmptyData minHeight="70vh" loading={isLoading.trends} />
				)}
				{isLoading.trends && (
					<CircularProgress
						color="#000"
						size={50}
						position="center"
						colorBg="rgba(0,0,0,0.05)"
						height="100%"
					/>
				)}
				{(subscribe.isActive || isAdmin) && (
					<Footer
						pagination={pagination.trends}
						paginationChange={onPaginationChange}
					/>
				)}
			</div>
			<ConfirmModal
				title={`Delete ${deleteInfo?.name}`}
				text="Are you sure you want to delete this trend?"
				loading={isLoading.trendDelete}
				open={deleteOpen}
				onClose={onCloseDeleteModal}
				onAgree={onDeleteAgree}
			/>
		</>
	);
};

export default TrendsContent;
