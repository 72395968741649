import React, { memo, useState } from "react";

import isEqual from "react-fast-compare";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

import { Edit, DeleteIcon } from "app/icons";
import Button, { BUTTON_COLOR_VARIANT, BUTTON_SIZE } from "app/uikit/button";
import { globalHandlerError } from "app/helper/handlerErrors";
import { asyncConfirm } from "app/components/modal/asyncConfirm";
import Switch from "app/uikit/switch";

import { ITraking } from "./types";
import style from "./style.module.scss";

interface Props {
	data: ITraking;
	onDeleteItem: (id: number) => void;
	onOpenDetails: (id: number) => void;
	onEnableTracking: (
		id: number,
		enable: boolean,
		cb: (enable: boolean) => void
	) => void;
}

const TrackingListItem: React.FC<Props> = ({
	data,
	onDeleteItem,
	onOpenDetails,
	onEnableTracking
}) => {
	const [enabled, setEnabled] = useState(data.is_enabled || false);
	const {
		id,
		name,
		people_count,
		events_count,
		last_updated_at_name,
		next_update_date
	} = data;

	const handleDeleteItem = async () => {
		try {
			const confirm = await asyncConfirm({
				title: "Delete list?",
				message: `Are you sure that you want to delete "${name}"?`,
				acceptText: "Yes, delete"
			});
			if (confirm) {
				onDeleteItem(id);
			}
		} catch (err) {
			console.log(err);
			globalHandlerError(err);
		}
	};

	const onGoToDetailsPage = () => {
		onOpenDetails(id);
	};

	return (
		<div className={style.item}>
			<div className={style["item__block-name"]}>
				<Tooltip placement="bottom-start" enterDelay={500} title={name}>
					<p className={cn(style.item__name, "font--700")}>{name}</p>
				</Tooltip>
			</div>
			<div>
				<span onClick={onGoToDetailsPage} className={style.item__round}>
					{people_count}
				</span>
			</div>
			<div>
				<span onClick={onGoToDetailsPage} className={style.item__round}>
					{events_count}
				</span>
			</div>
			<div>
				<span>{last_updated_at_name}</span>
			</div>
			<div>
				<span>{next_update_date}</span>
			</div>
			<div>
				<Switch
					name="enable"
					checked={enabled}
					onChange={() => {
						setEnabled(state => !state);
						onEnableTracking(id, !enabled, setEnabled);
					}}
				/>
			</div>
			<div
				className={style.item__actions}
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();
				}}
			>
				<Button
					className={style["btn-delete"]}
					size={BUTTON_SIZE.medium}
					colorVariant={BUTTON_COLOR_VARIANT.gradient}
					onClick={onGoToDetailsPage}
				>
					See details
				</Button>

				<Button
					className={style["btn--edit"]}
					colorVariant={BUTTON_COLOR_VARIANT.text}
				>
					<Edit color="#EB7D68" />
				</Button>

				<Button
					size={BUTTON_SIZE.medium}
					colorVariant={BUTTON_COLOR_VARIANT.text}
					onClick={handleDeleteItem}
				>
					<DeleteIcon color="#EB7D68" />
				</Button>
			</div>
		</div>
	);
};

export default memo(TrackingListItem, isEqual);
