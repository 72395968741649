import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";

const Profile = Loadable({
	loader: () => import("./profile")
});

const ScreenProfile = () => {
	return (
		<UserLayout>
			<Profile />
		</UserLayout>
	);
};

export default ScreenProfile;
