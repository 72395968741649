import React, { memo, useCallback, useEffect, useState } from "react";

import Modal from "../modal";

import style from "./style.module.scss";

interface Props {
	children: React.ReactNode;
	triggerKey: string;
	title?: React.ReactNode;
	showCloseIcon?: boolean;
	secondBtnText?: string;
}

export const InformationModal: React.FC<Props> = memo(
	({
		children,
		title,
		triggerKey,
		showCloseIcon = true,
		secondBtnText = "Ok"
	}) => {
		const [open, setOpen] = useState<boolean>(false);

		const onClose = useCallback(() => {
			localStorage.setItem(triggerKey, "true");
			setOpen(false);
		}, [setOpen, triggerKey]);

		useEffect(() => {
			const infoKey = localStorage.getItem(triggerKey);

			if (!infoKey) {
				setOpen(true);
			}
		}, [triggerKey]);

		return (
			<Modal
				showCloseIcon={showCloseIcon}
				classNameHeader={style.header}
				title={title}
				onClose={onClose}
				open={open}
				footer={{
					secondBtnText: secondBtnText,
					secondBtnAction: onClose,
					justify: "center",
					firstBtnHide: true
				}}
			>
				{children}
			</Modal>
		);
	}
);
