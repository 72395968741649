// @ts-nocheck
import { takeEvery, put, all, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import toastify from "app/utils/toastify";
import localStore from "app/helper/local-store";
import PATHS from "PATHS";
import directory from "app/directory/errors";
import { CommonEvents, eventTrack } from "app/utils/analyticsTracker";
import { isDev } from "app/utils/isDev";

import actionsUser from "../user/actions";

import services from "./services";
import types from "./types";
import actions from "./actions";

const dir = directory.en;

function* fetchSignIn({ credentials }: any) {
	try {
		let requestData = { ...credentials };
		delete requestData.remember;
		const response = yield call(services.fetchSignIn, requestData);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			localStore.setToken(response.data.token);
			if (isDev) {
				localStore.setInfo(response.data.user);
			}
			yield put(
				actions.fetchSignInSuccess({
					user: response.data.user,
					token: response.data.token
				})
			);
			yield put(actionsUser.getInfoSuccess(response.data.user));
			eventTrack(CommonEvents.successLogin());
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			yield put(actions.fetchSignUpFailure(error?.response?.data?.errors || ""));
		} else if (error?.response?.status === 403) {
			yield put(actions.fetchSignUpFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchSignUpFailure(""));
		}
	}
}

function* fetchSignUp({ credentials }) {
	try {
		const response = yield call(services.fetchSignUp, credentials);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchSignUpSuccess(response));
		}
		eventTrack(CommonEvents.successRegistration());
	} catch (error) {
		if (error?.response?.status === 422) {
			yield put(
				actions.fetchNewPasswordFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 401) {
			toastify.error(error?.response?.data?.message || error.message);
			yield put(actions.fetchSignUpFailure(error?.response?.data?.errors || ""));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchSignUpFailure(""));
		}
	}
}

function* fetchLogOut({ withoutRequest }) {
	try {
		let response = null;
		if (!withoutRequest) {
			response = yield call(services.fetchLogOut);
		}
		if (response?.name === "Error") {
			throw response;
		} else if ((response && !response.errors) || withoutRequest) {
			yield put(actions.fetchLogOutSuccess());
		}
	} catch (error) {
		yield put(actions.fetchLogOutFailure());
	}
	yield put(actionsUser.resetInfo());
	yield put(push(PATHS.auth.login));
}

function* fetchPasswordRecovery({ credentials, resetForm }) {
	try {
		const response = yield call(services.fetchPasswordRecovery, credentials);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchPasswordRecoverySuccess());
			toastify.success(response?.data?.message);
			resetForm();
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			yield put(
				actions.fetchPasswordRecoveryFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status >= 500) {
			toastify.error("Sorry, the server is temporarily unavailable");
			yield put(actions.fetchPasswordRecoveryFailure(""));
		} else if (error) {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchPasswordRecoveryFailure(""));
		}
	}
}

function* fetchNewPassword({ credentials }) {
	try {
		const response = yield call(services.fetchNewPassword, credentials);
		if (response.name === "Error") {
			throw response;
		} else if (response && !response.errors) {
			yield put(actions.fetchNewPasswordSuccess());
		}
	} catch (error) {
		if (error?.response?.status === 422) {
			yield put(
				actions.fetchNewPasswordFailure(error?.response?.data?.errors || "")
			);
		} else if (error?.response?.status === 403) {
			yield put(actions.fetchNewPasswordFailure(error?.response?.data?.message));
		} else {
			toastify.error(error?.response?.data?.message || dir.serverNotWorking);
			yield put(actions.fetchNewPasswordFailure(""));
		}
	}
}

export default function* root() {
	yield all([takeEvery(types.FETCH_SIGN_IN_REQUEST, fetchSignIn)]);
	yield all([takeEvery(types.FETCH_LOGOUT_REQUEST, fetchLogOut)]);
	yield all([takeEvery(types.FETCH_SIGN_UP_REQUEST, fetchSignUp)]);
	yield all([
		takeEvery(types.FETCH_PASSWORD_RECOVERY_REQUEST, fetchPasswordRecovery)
	]);
	yield all([takeEvery(types.FETCH_NEW_PASSWORD_REQUEST, fetchNewPassword)]);
}
