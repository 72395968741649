import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";
import PATHS from "PATHS";

const TrendCreate = Loadable({
	loader: () => import("./TrendCreate")
});

const PageCreateTrendDatabase: React.FC = () => {
	return (
		<UserLayout
			headerBackLinkTitle="Trends"
			headerBackLink={PATHS.cabinet.trends.database.path}
		>
			<TrendCreate />
		</UserLayout>
	);
};

export default PageCreateTrendDatabase;
