/* eslint-disable jsx-a11y/iframe-has-title */
import { memo } from "react";

import isEqual from "react-fast-compare";

import Modal from "app/components/modal";

import styles from "./style.module.scss";

interface Props {
	open: boolean;
	onClose: () => void;
}

const TrackingActiveModalVip: React.FC<Props> = ({ open, onClose }) => (
	<Modal
		title="See it in action"
		size="auto"
		open={open}
		onClose={onClose}
		showCloseIcon
	>
		<div>
			<iframe
				className={styles.iframe}
				width="1120"
				height="630"
				allow="fullscreen; picture-in-picture"
				src="https://www.youtube.com/embed/On4fVtnbXjs"
			/>
		</div>
	</Modal>
);

export default memo(TrackingActiveModalVip, isEqual);
