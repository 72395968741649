import { useLayoutEffect } from "react";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";
import actions from "app/store/trends/actions";
import PATHS from "PATHS";

const Trends = Loadable({
	loader: () => import("./TrendsItemDetails")
});

const PageTrendDetails = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		dispatch(actions.getTrend(Number(id)));
	}, [id, dispatch]);

	return (
		<UserLayout
			headerBackLinkTitle="Reports"
			headerBackLink={PATHS.cabinet.trends.reports.path}
		>
			<Trends />
		</UserLayout>
	);
};

export default PageTrendDetails;
