// @ts-nocheck
import { useEffect, useMemo, useState, useCallback } from "react";

import { MenuItem } from "@material-ui/core";
import { ClickAwayListener } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import { InputAdornment } from "@material-ui/core";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import cl from "classnames/bind";
import debounce from "lodash/debounce";

import Avatar from "app/components/avatar";
import Input from "app/uikit/input";
import CircularProgress from "app/uikit/CircularProgress";

import Checkbox from "../checkbox";
import { DownArrow, Search } from "../../icons";
import Chip from "../chip";

import useStyles from "./style";

const limit = 50;
const itemSize = 40;
const largeOptionSize = 55;

const AutocompleteAction = ({
	action,
	name,
	error,
	placeholder,
	value,
	filter = {},
	initialOptions,
	disablePortal,
	withFilter,
	largeItemOption,
	formateData,
	onChange,
	multiple,
	disabled
}) => {
	const classes = useStyles();
	const cx = cl.bind(classes);
	const [open, setOpen] = useState(false);
	const [itemStatusMap, setItemStatusMap] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [params, setParams] = useState({ ...filter, limit: limit });
	const [pendingValue, setPendingValue] = useState("");
	const [data, setData] = useState({ count: 0, items: [] });
	const [loading, setLoading] = useState(true);
	const isItemLoaded = index => !!itemStatusMap[index];

	const getData = useCallback(async () => {
		try {
			const response = await action(params);
			if ((params as any).offset) {
				setData(state => ({
					...state,
					count: response.data.count,
					items: state.items.concat(
						formateData ? formateData(response.data.items) : response.data.items
					)
				}));
			} else {
				let res = response.data;
				res.items = formateData ? formateData(res.items) : res.items;
				if (initialOptions?.length && pendingValue === "") {
					res.count = res.count + initialOptions.length;
					res.items = initialOptions.concat(
						formateData ? formateData(res.items) : res.items
					);
				}
				setData(res);
			}
		} catch (e) {
			console.log(e);
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, params]);

	useEffect(() => {
		if (withFilter) {
			if (Object.keys(filter).length) {
				getData();
			}
		} else {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	useEffect(() => {
		if (Object.keys(filter).length) {
			setParams({ ...filter, limit: limit });
		}
	}, [filter]);

	const loadMoreItems = useCallback(
		(startIndex, stopIndex) => {
			if (((params as any).offset || 0) + limit < stopIndex) {
				for (let index = startIndex; index <= stopIndex; index++) {
					setItemStatusMap(state => ({ ...state, [index]: true }));
				}
				setParams(state => ({
					...state,
					offset: (state.offset || 0) + limit
				}));
			}
		},
		[params]
	);

	const onChoose = useCallback(
		option => {
			const isArray = Array.isArray(value) && multiple;
			let formatedValues = isArray && [...value];
			const index = isArray && value.findIndex(el => el.name === option.name);
			if (isArray && index !== -1) {
				formatedValues.splice(+index, 1);
			} else if (isArray) {
				formatedValues.push(option);
			}

			onChange(formatedValues || option);
			!multiple && setOpen(false);
		},
		[multiple, onChange, value]
	);

	const renderRow = props => {
		const { index, style } = props;
		if (data?.items && data.items[index]) {
			const option = data.items[index];
			const { imgSrc, name, description } = option;
			const isActive =
				(Array.isArray(value) &&
					value.find(el => el.name === (option as any).name)) ||
				value?.name === (option as any)?.name;

			return (
				<MenuItem
					className={cx("optionItem", { optionItemActive: isActive })}
					onClick={() => onChoose(option)}
					style={{ ...style }}
					value={index}
				>
					<div className={cx("text", "menuItemBlock")}>
						{(multiple && (
							<div className={classes.text}>
								<Checkbox checked={isActive} />

								<span className="main-text main-text_bold">{(option as any).name}</span>
							</div>
						)) || (
							<>
								{imgSrc && <Avatar className={classes.imgItem} src={imgSrc} />}

								<div
									className={cx("innerOptionItem", { innerOptionItemWithImg: imgSrc })}
								>
									<span className={cl(classes.menuItemText, "main-text main-text_bold")}>
										{name}
									</span>

									{name && (
										<span className={cl(classes.menuItemText, "small-text")}>
											{description}
										</span>
									)}
								</div>
							</>
						)}
					</div>
				</MenuItem>
			);
		}
		return (
			<MenuItem
				className={classes.optionItem}
				style={style}
				value={index}
				disabled={true}
			>
				<div className={classes.text}>
					<span className="main-text main-text_bold">Loading...</span>
				</div>
			</MenuItem>
		);
	};

	const handleChangeSearch = e => {
		const value = e.target.value;
		delayed(value);
		setPendingValue(value);
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const delayed = useCallback(
		debounce(v => {
			setParams(state => ({ ...state, "filter[search]": v, offset: null }));
			setItemStatusMap({});
			setLoading(true);
		}, 500),
		[debounce]
	);

	const clickAwayHandler = () => {
		setOpen(false);
	};

	const getHeight = useMemo(() => {
		const countItem = data.items?.length;
		let height = 16;
		if (countItem === 0) return 0;
		if (countItem >= 6) {
			height = ((largeItemOption && largeOptionSize) || itemSize) * 6 + 16;
		} else if (countItem > 0 && countItem < 6) {
			height = ((largeItemOption && largeOptionSize) || itemSize) * countItem + 16;
		}
		return height >= 296
			? largeItemOption && largeOptionSize
				? 289
				: 296
			: height;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onDelete = index => {
		const mewArr = [...value];
		mewArr.splice(+index, 1);
		onChange(mewArr);
	};

	return (
		<>
			<ButtonBase
				disabled={disabled}
				disableRipple
				className={cx("button", { error: error })}
				onClick={handleClick}
			>
				{(multiple && (
					<Chip variant="bordered" list={value} onDelete={onDelete} />
				)) || (
					<Input
						name={name}
						className={cx("input")}
						id={name}
						disabled={disabled}
						readOnly={true}
						value={value?.name || ""}
						fullWidth
						iconPosition="end"
						icon={<DownArrow />}
					/>
				)}
			</ButtonBase>

			<ClickAwayListener
				mouseEvent="onMouseDown"
				touchEvent="onTouchStart"
				onClickAway={clickAwayHandler}
			>
				<Popper
					disablePortal={!!disablePortal}
					id={`${name}-popper`}
					open={open}
					style={{
						width:
							(anchorEl as any)?.clientWidth > 310
								? (anchorEl as any)?.clientWidth
								: 310
					}}
					anchorEl={anchorEl}
					modifiers={{
						flip: {
							enabled: false
						}
					}}
					placement="bottom-start"
					className={classes.popper}
				>
					<InputBase
						autoFocus
						fullWidth
						className={cl(classes.inputSearchSecond, classes.inputSearch)}
						placeholder={placeholder}
						value={pendingValue}
						onChange={handleChangeSearch}
						startAdornment={
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						}
					/>

					<InfiniteLoader
						minimumBatchSize={45}
						isItemLoaded={isItemLoaded}
						itemCount={data.count}
						loadMoreItems={loadMoreItems}
					>
						{/* @ts-expect-error TS(7031): Binding element 'onItemsRendered' implicitly has a... Remove this comment to see the full error message */}
						{({ onItemsRendered, ref }) => {
							return (
								<FixedSizeList
									itemCount={data.count}
									height={getHeight}
									width="100%"
									itemSize={(largeItemOption && largeOptionSize) || itemSize}
									ref={ref}
									overscanCount={5}
									style={{ position: "relative" }}
									innerElementType="ul"
									onItemsRendered={onItemsRendered}
									className={cx("infiniteBlock", {
										infiniteBlockEmpty: data.count === 0
									})}
								>
									{renderRow}
								</FixedSizeList>
							);
						}}
					</InfiniteLoader>
					{!data.items.length &&
						((loading && <CircularProgress color="#000" height={50} />) || (
							<div className={classes.noOptions}>
								No items have been found by your request
							</div>
						))}
				</Popper>
			</ClickAwayListener>
		</>
	);
};
export default AutocompleteAction;
