import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";
import PATHS from "PATHS";

const TrackingEditListContent = Loadable({
	loader: () => import("./TrackingEditList")
});

const PageScreenTrackingEditList: React.FC = () => (
	<UserLayout
		headerBackLink={PATHS.cabinet.tracking.path}
		headerBackLinkTitle="Tracking"
	>
		<TrackingEditListContent />
	</UserLayout>
);

export default PageScreenTrackingEditList;
