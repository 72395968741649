import React from "react";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";
import PATHS from "PATHS";

const TrendEdit = Loadable({
	loader: () => import("./TrendEdit")
});

const PageTrendDatabaseEdit: React.FC = () => {
	return (
		<UserLayout
			headerBackLinkTitle="Trends"
			headerBackLink={PATHS.cabinet.trends.database.path}
		>
			<TrendEdit />
		</UserLayout>
	);
};

export default PageTrendDatabaseEdit;
