import { memo } from "react";

import isEqual from "react-fast-compare";

import TermsAndPrivacy from "app/components/terms-and-privacy";

import style from "./style.module.scss";

const FooterConfiguration = () => (
	<footer className={style.footer}>
		<span className={style.text}>
			&copy; {new Date().getFullYear()} Tenoris Inc.
		</span>
		<div>
			<TermsAndPrivacy />
		</div>
	</footer>
);

export default memo(FooterConfiguration, isEqual);
