import React, { Suspense, useEffect } from "react";

import { ConnectedRouter } from "connected-react-router/immutable";
import { LastLocationProvider } from "react-router-last-location";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import actionHandBook from "app/store/handBooks/actions";
import actionGeneralSettings from "app/store/appSettings/actions";
import actionsUser from "app/store/user/actions";
import PageNotFound from "app/screens/404";
import { Aside } from "app/components/includes/aside";
import localStore from "app/helper/local-store";
import { usePrevious } from "app/hooks/usePrevious";
import config from "app/config";
import { IUserState } from "app/store/user/reducer";
import { configSelect } from "app/store/select";
import Configuration from "app/screens/cabinet/configurationV2";
import SubscriptionSuccess from "app/screens/account/subscriptionSuccess";
import CircularProgress from "app/uikit-V2/CircularProgress";
import PATHS from "PATHS";

import NotFoundRouter from "./notFoundRouter";
import history from "./history";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import { ROUTERS } from "./routersList";

const ConnectedRouterProps = {
	history: history
};

const LayoutRouting: React.FC = () => {
	const userInfo: IUserState = useSelector(configSelect.selectUserDetails);
	const {
		user: { permissions, email, name, id, is_onboard_passed }
	} = userInfo;
	const dispatch = useDispatch();
	const prevProps = usePrevious({ email, id, name });

	const token = localStore.getAccessToken();

	useEffect(() => {
		// this check is needed to prevent overwriting the data of the first login
		if ((id && !prevProps) || (!prevProps && token)) {
			dispatch(actionsUser.getInfo());
		}
	}, [dispatch, id, prevProps, token]);

	useEffect(() => {
		const sameUser = prevProps?.email === email;
		if (Object.keys(permissions).length && email && !sameUser) {
			dispatch(actionHandBook.getHandBooks());
			dispatch(actionGeneralSettings.getGeneralSettings());
		}
	}, [permissions, dispatch, email, id, name, prevProps]);

	const isOnboardPassed = config.onBoarding ?? !!is_onboard_passed;

	const canRenderAside =
		window.location.pathname === "/test"
			? false
			: isOnboardPassed && !!Object.keys(permissions).length && !!token;

	if (token && !id) {
		return <CircularProgress color="black" size={50} variant="center-absolute" />;
	}

	return (
		<Suspense>
			<ConnectedRouter {...ConnectedRouterProps}>
				<LastLocationProvider>
					<div className={cn({ "page-wrapper": canRenderAside })}>
						{canRenderAside && <Aside />}
						<Switch>
							<PrivateRoute
								exact
								permissions={permissions}
								permission
								path={PATHS.account.subscriptionSuccess}
								Component={SubscriptionSuccess}
							/>
							{token && !isOnboardPassed && (
								<>
									<PrivateRoute
										exact
										permissions={permissions}
										permission
										path={PATHS.cabinet.configuration}
										Component={Configuration}
									/>
									<Redirect from="*" to={PATHS.cabinet.configuration} />
								</>
							)}
							{ROUTERS.map((item, i) => {
								if (!item.active) return null;
								if (item?.conditionsActive && !item?.conditionsActive(userInfo)) {
									return null;
								}

								if (item.isPrivate) {
									return (
										<PrivateRoute
											key={i}
											exact
											permissions={permissions}
											permission={item.permission}
											path={item.path}
											Component={item.component}
										/>
									);
								}
								return (
									<PublicRoute
										key={i}
										exact
										path={item.path}
										Component={item.component}
									/>
								);
							})}
							{token && isOnboardPassed && (
								<Redirect
									from={PATHS.cabinet.configuration}
									exact
									to={config.defaultPage.link}
								/>
							)}
							<Redirect from="/" exact to={config.defaultPage.link} />
							<Route exact path={PATHS.pageNotFound} component={PageNotFound} />;
							<NotFoundRouter />
						</Switch>
					</div>
				</LastLocationProvider>
			</ConnectedRouter>
		</Suspense>
	);
};

export default LayoutRouting;
