import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";
import PATHS from "PATHS";
import useQuery from "app/hooks/useQuery";
import { Nullable } from "app/types";
import { asyncConfirm } from "app/components/modal/asyncConfirm";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import toastify from "app/utils/toastify";

import { IPerson, IResponseCompanyDetails } from "../types";
import { getDetailsTracking } from "../details/TrackingDetails.utils";
import { PropsTriggerDetails } from "../triggerDetails";

import { getPersonDetails } from "./PersonDetails.utils";

const TriggerDetails = Loadable<PropsTriggerDetails>({
	loader: () => import("../triggerDetails")
});

const PageScreenTrackingPersonDetails: React.FC = () => {
	const { id, companyId, personId } = useParams<Record<string, string>>();
	const [data, setData] = useState<IResponseCompanyDetails>(null);
	const [details, setDetails] = useState<Nullable<IPerson>>(null);
	const history = useHistory();
	const query = useQuery();
	const prevTrackingList = query.get("prev-tracking-list");

	const currentId = prevTrackingList || id;

	useEffect(() => {
		(async () => {
			await getDetailsTracking(currentId, setData);
		})();
	}, [currentId, setData]);

	useEffect(() => {
		getPersonDetails(id, companyId, personId, setDetails);
	}, [id, companyId, personId, setDetails]);

	const onDeletePerson = useCallback(async () => {
		const confirm = await asyncConfirm({
			title: "Delete this person?",
			message: "Are you sure you want to delete this person?",
			declineText: "No",
			acceptText: "Yes"
		});

		if (confirm) {
			try {
				const res = await API.delete(
					ApiUrls.account.leadObservation.companiesSets.companies.person.details(
						id,
						companyId,
						personId
					)
				);
				toastify.success(res?.data?.message || res?.data?.messages);
				history.push(PATHS.cabinet.tracking.details.url(id));
			} catch (err) {
				toastify.error(err);
			}
		}
	}, [history, id, companyId, personId]);

	return (
		<UserLayout
			headerBackLink={PATHS.cabinet.tracking.details.url(currentId)}
			headerBackLinkTitle={data ? `a list ${data.name}` : ""}
		>
			<TriggerDetails
				trackingId={id}
				companyId={companyId}
				personId={personId}
				details={details}
				onDelete={onDeletePerson}
			/>
		</UserLayout>
	);
};

export default PageScreenTrackingPersonDetails;
