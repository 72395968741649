// @ts-nocheck
import api from "app/api";

const API = api();

const getInfo = async () => {
	try {
		return await API.get("/account/user");
	} catch (error) {
		return error;
	}
};

const changeInfo = async data => {
	try {
		return await API.put("/account/user", data);
	} catch (error) {
		return error;
	}
};

const createPayment = async params => {
	try {
		return await API.post("/account/payments/sessions", params);
	} catch (error) {
		return error;
	}
};

const cancelSubscription = async () => {
	try {
		return await API.delete("/account/user/cancel-subscription");
	} catch (error) {
		return error;
	}
};

export default {
	getInfo,
	changeInfo,
	createPayment,
	cancelSubscription
};
