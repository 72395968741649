import { useEffect, useLayoutEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";

import UserProfileWrapper from "app/components/user-profile-layout";
import { configSelect } from "app/store/select";
import { globalHandlerError } from "app/helper/handlerErrors";
import { IOption, IPagination } from "app/types";
import { ApiUrls } from "app/api/apiUrls";
import { API } from "app/api";
import Button from "app/uikit-V2/Button";
import { FileIcon } from "app/icons";
import config from "app/config";
import { mapFilter, prepareParams, urlEncodeQueryParams } from "app/helper";
import toastify from "app/utils/toastify";
import download from "app/utils/download";
import localStore from "app/helper/local-store";
import directory from "app/directory/errors";
import PATHS from "PATHS";
import useSaveParamsByLocation from "app/hooks/useSaveParamsByLocation";
import { IResponseCompanies } from "app/screens/cabinet/tracking/types";
import { asyncConfirm } from "app/components/modal/asyncConfirm";

import { CompanyTable } from "../components/CompanyTable";
import Filter from "../components/filter";

import style from "./style.module.scss";

const dir = directory.en;

type UserLeadsParams = {
	id: string;
	trackingId: string;
};

const DEFAULT_PARAMS: {
	filter: { event_ids: IOption[] };
	pagination: IPagination;
} = {
	filter: { event_ids: [] },
	pagination: config.ui.pagination.default
};

const UserTrackingDetails = () => {
	const { id, trackingId } = useParams<UserLeadsParams>();
	const {
		user: { created_at }
	} = useSelector(configSelect.users);

	const {
		value: initialParams,
		setValue: setValueSaveParams
	} = useSaveParamsByLocation("AdminTrackingDetails", {
		initialValues: DEFAULT_PARAMS,
		condition: lastLocation =>
			lastLocation?.pathname?.includes(`/admin/users/${id}`)
	});

	const history = useHistory();
	const [filter, setFilter] = useState<{ event_ids: IOption[] }>(
		initialParams?.filter
	);

	const [pagination, setPagination] = useState<IPagination>(
		initialParams?.pagination
	);
	const [companiesList, setCompaniesList] = useState<{
		data: IResponseCompanies;
		loading: boolean;
	}>({
		data: { items: [], count: 0 } as IResponseCompanies,
		loading: true
	});

	useEffect(() => {
		setValueSaveParams({
			filter,
			pagination
		});
	}, [setValueSaveParams, filter, pagination]);

	useLayoutEffect(() => {
		(async () => {
			try {
				setCompaniesList(state => ({ ...state, loading: true }));
				if (!id) return;
				const response: AxiosResponse = await API.get(
					ApiUrls.admin.users.leadObservation.companies.list(id, trackingId),
					{
						params: prepareParams({
							pagination: pagination,
							filter: {
								...filter,
								event_ids: mapFilter(filter.event_ids, "stringIds")
							}
						})
					}
				);
				setCompaniesList(state => ({
					...state,
					data: response?.data,
					loading: false
				}));
			} catch (err) {
				globalHandlerError(err);
			} finally {
				setCompaniesList(state => ({ ...state, loading: false }));
			}
		})();
	}, [id, trackingId, pagination, filter]);

	const handleChangeFilter = (values: IOption[]) => {
		setFilter(state => ({ ...state, event_ids: values }));
	};

	const onExportCsv = () => {
		toastify.info(dir.downLoadFile);
		download(
			`${ApiUrls.admin.users.leadObservation.export(
				id,
				trackingId
			)}?${urlEncodeQueryParams({
				Authorization: localStore.getAccessToken(),
				...prepareParams({
					filter: {
						...filter,
						event_ids: mapFilter(filter.event_ids, "stringIds")
					}
				})
			})}`
		);
	};

	const handlePaginationChange = (data: IPagination) => {
		setPagination(state => ({
			...state,
			...data
		}));
	};

	const onBack = () => {
		history.push(PATHS.adminPanel.userLeads.url(id));
	};

	const onRemovePerson = async (
		personId: string,
		companyId: string,
		name?: string
	) => {
		try {
			const agree = await asyncConfirm({
				title: "Delete this person?",
				message: `Are you sure you want to delete ${name || "this person"}?`,
				acceptText: "Yes",
				declineText: "No"
			});
			if (!agree) return;
			const response = await API.delete(
				ApiUrls.admin.users.leadObservation.companies.person.details(
					id,
					trackingId,
					companyId,
					personId
				)
			);
			toastify.success(response?.data?.messages);
			setCompaniesList(state => ({
				...state,
				data: {
					...state.data,
					items: state.data.items.map(item => {
						if (item.id === companyId) {
							return {
								...item,
								persons: item.persons.filter(personItem => personItem.id !== personId)
							};
						}
						return item;
					})
				}
			}));
		} catch (err) {
			console.log(err);
			toastify.error(err?.response?.data?.message || err?.message);
		}
	};

	return (
		<UserProfileWrapper date={created_at}>
			<div className={style.controls}>
				<Button size="medium" variant="secondary" onClick={onBack}>
					Go to tracking
				</Button>
			</div>
			<Filter onChangeFilter={handleChangeFilter} />
			<div className={style.export}>
				<Button
					className={style.btn_download}
					size="medium"
					variant="secondary"
					onClick={onExportCsv}
				>
					<FileIcon /> Export to csv
				</Button>
			</div>

			<CompanyTable
				userId={id}
				onRemovePerson={onRemovePerson}
				trackingListId={trackingId}
				data={companiesList.data}
				loading={companiesList.loading}
				pagination={pagination}
				onPaginationChange={handlePaginationChange}
			/>
		</UserProfileWrapper>
	);
};

export default UserTrackingDetails;
