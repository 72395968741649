import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import UserProfileWrapper from "app/components/user-profile-layout";
import { configSelect } from "app/store/select";
import Button from "app/uikit-V2/Button";
import PATHS from "PATHS";
import TriggerDetails from "app/screens/cabinet/tracking/triggerDetails";
import { ICompany } from "app/screens/cabinet/tracking/types";
import { Nullable } from "app/types";

import styles from "./style.module.scss";
import { getCompanyDetails } from "./TrackingCompanyDetails.utils";

type UserLeadsParams = {
	id: string;
	companyId: string;
	trackingId: string;
};

const UserTrackingCompanyDetails = () => {
	const { id, trackingId, companyId } = useParams<UserLeadsParams>();
	const {
		user: { created_at }
	} = useSelector(configSelect.users);
	const history = useHistory();
	const [details, setDetails] = useState<Nullable<ICompany>>(null);

	useEffect(() => {
		getCompanyDetails(id, trackingId, companyId, setDetails);
	}, [id, companyId, trackingId, setDetails]);

	const onBack = useCallback(() => {
		history.push(PATHS.adminPanel.userLeadsDetails.url(id, trackingId));
	}, [history, id, trackingId]);

	return (
		<UserProfileWrapper date={created_at}>
			<div className={styles.controls}>
				<Button size="medium" variant="secondary" onClick={onBack}>
					Go to tracking
				</Button>
			</div>

			<TriggerDetails
				isCompany
				title="Company Details"
				userId={id}
				trackingId={trackingId}
				companyId={companyId}
				details={details}
			/>
		</UserProfileWrapper>
	);
};

export default UserTrackingCompanyDetails;
