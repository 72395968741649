// @ts-nocheck
import { useCallback, useEffect } from "react";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import UserLayout from "app/components/includes/user-layout";
import PageTitle from "app/components/page-title";
import { formateDate } from "app/helper";
import actions from "app/store/users/actions";
import PATHS from "PATHS";

const tabsLinks = (id: number | string) => [
	{
		path: PATHS.adminPanel.userAccount.replace(":id", id),
		title: "Profile"
	},
	{
		path: PATHS.adminPanel.userLeads.url(id),
		title: "Custom Leads"
	}
];

const UserProfileWrapper = props => {
	const {
		match: {
			params: { id }
		}
	} = props;
	const { children, subtitleAddingContent, showAddingContent, date } = props;

	const dispatch = useDispatch();

	const fetchUserData = useCallback(() => {
		dispatch(actions.getUser(id));
	}, [id, dispatch]);

	useEffect(() => {
		fetchUserData();
	}, [fetchUserData]);

	return (
		<UserLayout
			headerBackLink={PATHS.adminPanel.users}
			headerBackLinkTitle="Users"
		>
			<PageTitle
				title="User account"
				subtitle={`Was registered ${(date && formateDate(date)) || ""}`}
				showAddingContent={showAddingContent}
				subtitleAddingContent={subtitleAddingContent}
			/>

			<div className="profile-nav">
				{tabsLinks(id).map(tab => (
					<NavLink to={tab.path} className="profile-nav__link" key={tab.title}>
						{tab.title}
					</NavLink>
				))}
			</div>

			<div className="profile-wrapper">{children}</div>
		</UserLayout>
	);
};

export default withRouter(UserProfileWrapper);
