// @ts-nocheck

import Nodata from "app/components/no-data";

const EmptyData = ({ loading, content, text, minHeight = 415 }: any) => {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				textAlign: "center",
				paddingTop: "50px",
				paddingBottom: "50px",
				minHeight: minHeight
			}}
		>
			{!loading && (content || <Nodata text={text} />)}
		</div>
	);
};

export default EmptyData;
