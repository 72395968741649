import React, { memo, useEffect, useState } from "react";

import { Container, Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import config from "app/config/config";
import TrackingActiveModal from "app/screens/cabinet/configuration/components/TrackingActiveModal";
import { useIntercom } from "app/hooks/useIntercom";

import TermsAndPrivacy from "../terms-and-privacy";

import style from "./style.module.scss";
import Header from "./header";
import Footer from "./footer";

interface Props {
	header?: string;
	headerBackLink?: string;
	headerBackLinkTitle?: string;
	children?: JSX.Element;
}

const UserLayout: React.FC<Props> = ({
	header,
	headerBackLink,
	headerBackLinkTitle,
	children
}) => {
	const [openTrackingActiveModal, setOpenTrackingActiveModal] = useState(false);
	const history = useHistory();

	const onCloseTrackingActiveModal = () => {
		setOpenTrackingActiveModal(false);
	};

	const location = useLocation();

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const shouldOpen = query.get(config.showTrackingModal) === "true";
		if (shouldOpen) {
			setOpenTrackingActiveModal(true);
			history.replace(window.location.pathname);
		}
	}, [history, location]);

	useIntercom();

	return (
		<>
			<div className="page-main">
				<Header
					userHeader
					className={header}
					headerBackLink={headerBackLink}
					headerBackLinkTitle={headerBackLinkTitle}
				/>
				<main className="main">{children}</main>
				<Footer className="m-t--auto p-x--24">
					<Container maxWidth={false}>
						<Grid container justify="space-between" alignItems="center">
							<Grid item>
								<span className={style.text}>
									&copy; {new Date().getFullYear()} Tenoris Inc.
								</span>
							</Grid>
							<Grid item>
								<TermsAndPrivacy />
							</Grid>
						</Grid>
					</Container>
				</Footer>
			</div>

			<TrackingActiveModal
				open={openTrackingActiveModal}
				onClose={onCloseTrackingActiveModal}
			/>
		</>
	);
};

export default memo(UserLayout);
