type Iid = string | number;

const PATHS = {
	test: "/test",
	auth: {
		login: "/login",
		registration: "/registration",
		emailVerification: "/email-verification",
		emailVerificationExpired: "/email-verification-expired",
		emailVerificationConfirmed: "/email-is-already-confirmed",
		passwordRecovery: "/password-recovery",
		verificationCode: "/verification-code",
		newPassword: "/new-password",
		errorSendPwEmail: "/error-sending-reset-email",
		errorServerUnavailable: "/error-server-unavailable"
	},
	cabinet: {
		configuration: "/configuration",
		trends: {
			database: {
				path: "/trends-database",
				details: {
					path: "/trends-database/:id",
					url: (id: Iid) => `/trends-database/${id}`
				}
			},
			reports: {
				path: "/reports",
				details: {
					path: "/trends/:id",
					url: (id: Iid) => `/trends/${id}`
				}
			}
		},
		tracking: {
			path: "/tracking",
			create: "/tracking/create",
			details: {
				path: "/tracking/:id",
				url: (id: Iid) => `/tracking/${id}`,
				edit: {
					path: "/tracking/:id/edit",
					url: (id: Iid) => `/tracking/${id}/edit`
				}
			},
			company: {
				path: "/tracking/:id/company/:companyId",
				url: (id: Iid, companyId: Iid) => `/tracking/${id}/company/${companyId}`,

				person: {
					path: "/tracking/:id/company/:companyId/person/:personId",
					url: (id: Iid, companyId: Iid, personId: Iid) =>
						`/tracking/${id}/company/${companyId}/person/${personId}`
				}
			}
		}
	},
	adminPanel: {
		users: "/admin/users",
		userAccount: "/admin/users/:id/account",
		userLeads: {
			path: "/admin/users/:id/tracking",
			url: (id: Iid) => `/admin/users/${id}/tracking`
		},
		userLeadsDetails: {
			path: "/admin/users/:id/tracking/:trackingId",
			url: (id: Iid, trackingId: Iid) =>
				`/admin/users/${id}/tracking/${trackingId}`
		},
		userLeadsCompanyDetails: {
			path: "/admin/users/:id/tracking/:trackingId/company/:companyId",
			url: (id: Iid, trackingId: Iid, companyId: Iid) =>
				`/admin/users/${id}/tracking/${trackingId}/company/${companyId}`
		},
		userLeadsPersonDetails: {
			path:
				"/admin/users/:id/tracking/:trackingId/company/:companyId/person/:personId",
			url: (id: Iid, trackingId: Iid, companyId: Iid, personId: Iid) =>
				`/admin/users/${id}/tracking/${trackingId}/company/${companyId}/person/${personId}`
		},
		userLeadsAddNewList: "/admin/users/:id/leads/add-new-list",
		employees: "/admin/employees",
		settings: "/admin/settings",
		infoDashboard: "/admin/info-dashboard",
		trendCreate: "/admin/trend/create",
		trendEdit: "/admin/trend/edit/:id",
		trends: {
			database: {
				create: "/admin/trends-database/create",
				edit: {
					path: "/admin/trends-database/edit/:id",
					url: (id: Iid) => `/admin/trends-database/edit/${id}`
				}
			}
		}
	},
	account: {
		profile: "/account/profile",
		subscriptionSuccess: "/account/profile/payment-success"
	},
	pageNotFound: "/page-not-found",
	testUrl: "/test"
};

export default PATHS;
