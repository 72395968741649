import React, { memo, useMemo } from "react";

import isEqual from "react-fast-compare";
import { useSelector } from "react-redux";

import { InformationModal } from "app/components/informationModal";
import { configSelect } from "app/store/select";
import Typography from "app/uikit-V2/Typography";

import Icon from "./icon";
import styles from "./style.module.scss";

const INFO_KEY = "trends-information";

const TrendsInformationModal: React.FC = () => {
	const { id } = useSelector(configSelect.selectUser);
	const infoKey = useMemo(() => `${INFO_KEY}-${id}`, [id]);

	return (
		<InformationModal
			secondBtnText="Got it"
			showCloseIcon={false}
			triggerKey={infoKey}
		>
			<div className={styles.container}>
				<Icon />
				<Typography variant="h3" marginBottom="22px">
					Hello! And welcome to Signum.AI 👋
				</Typography>
				<Typography>
					Here you'll find insights on markets, companies, and people,{" "}
					<strong>updated weekly.</strong> Feel free to explore all the options!
				</Typography>
			</div>
		</InformationModal>
	);
};

export default memo(TrendsInformationModal, isEqual);
