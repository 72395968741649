import { memo } from "react";

import HideContent from "app/components/hideContent";
import defaultTrendImg from "assets/img/trend.jpg";
import { useIsAdmin } from "app/hooks/useIsAdmin";

import TrendsCard from "./trendCard";

const mokeTrend = {
	id: 109,
	datetime: "2022-02-21 00:00:00",
	language: { id: 2, name: "Russian", iso_code: "ru" },
	name: "Test Trend",
	short_description: "Short desctiotion test trend",
	img: defaultTrendImg
};
const data = new Array(3).fill(mokeTrend);

const HideContentSignals = () => {
	const { isAdmin, loading } = useIsAdmin();
	if (isAdmin || loading) return null;

	return (
		<HideContent
			data={data?.map((compain, _i) => (
				<TrendsCard key={_i} data={compain} />
			))}
		/>
	);
};

export default memo(HideContentSignals);
