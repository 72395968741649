import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";

const Employees = Loadable({
	loader: () => import("./Employees")
});

const PageEmployees = () => {
	return (
		<UserLayout>
			<Employees />
		</UserLayout>
	);
};

export default PageEmployees;
