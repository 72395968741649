export default {
	GET_INFO_USER_REQUEST: "GET_INFO_USER_REQUEST",
	GET_INFO_USER_SUCCESS: "GET_INFO_USER_SUCCESS",
	GET_INFO_USER_FAILURE: "GET_INFO_USER_FAILURE",

	CHANGE_INFO_USER_REQUEST: "CHANGE_INFO_USER_REQUEST",
	CHANGE_INFO_USER_SUCCESS: "CHANGE_INFO_USER_SUCCESS",
	CHANGE_INFO_USER_FAILURE: "CHANGE_INFO_USER_FAILURE",

	RESET_INFO_USER: "RESET_INFO_USER",

	TRAINING_PASSED: "TRAINING_PASSED",

	CANCEL_SUBSCRIPTION_REQUEST: "CANCEL_SUBSCRIPTION_REQUEST",
	CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
	CANCEL_SUBSCRIPTION_FAILURE: "CANCEL_SUBSCRIPTION_FAILURE"
};
