import React, { useLayoutEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import Loadable from "app/utils/Loadable";
import UserLayout from "app/components/includes/user-layout";
import PATHS from "PATHS";

const TrackingDetailsContent = Loadable({
	loader: () => import("./TrackingDetails")
});

const PageScreenTrackingDetails: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	useLayoutEffect(() => {
		if (isNaN(Number(id))) {
			history.push(PATHS.pageNotFound);
		}
	}, [id, history]);

	return (
		<UserLayout
			headerBackLink={PATHS.cabinet.tracking.path}
			headerBackLinkTitle="Tracking"
		>
			<TrackingDetailsContent />
		</UserLayout>
	);
};

export default PageScreenTrackingDetails;
